import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CloseButton, InputNumber } from 'components/common';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { gamesService } from 'services';

type PopupProps = PopupController & {
  item: {
    zone: string;
    gate: string;
  };
};

const INIT_VALUE = 100;

const PopupGameBet = ({ onClose, item }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, setValue, watch } = useForm({ mode: 'onChange' });
  const { amount = INIT_VALUE } = watch();

  const { mutate: createBet, isLoading } = useMutation(gamesService.createBet, {
    onSuccess: () => {
      enqueueSnackbar('Đặt lệnh thành công');
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createBet({ ...values, ...item, game: ' ' });
    })();
  };

  useEffect(() => {
    setValue('amount', INIT_VALUE);
  }, [setValue]);

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Invest</DialogTitle>
      <DialogContent>
        <div className='flex justify-center items-center space-x-2'>
          <Button size='medium' onClick={() => setValue('amount', Math.max(10, amount - 10))}>
            <img src={require('assets/icons/Icon.Minus.svg').default} className='scale-125' />
          </Button>
          <Controller
            control={control}
            name='amount'
            defaultValue=''
            rules={{
              required: 'Số tiền đặt không được để trống',
              min: { value: 9, message: 'Số tiền rút tối thiểu là 10' },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                variant='outlined'
                InputProps={{
                  inputComponent: InputNumber,
                  classes: { input: 'text-base py-3' },
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#FFF4',
                    borderWidth: '1px !important',
                    borderRadius: '4px !important',
                    boxShadow: '#0002 0px 4px 8px',
                  },
                }}
                error={!!error}
              />
            )}
          />
          <Button size='medium' onClick={() => setValue('amount', amount + 10)}>
            <img src={require('assets/icons/Icon.Add.svg').default} className='scale-125' />
          </Button>
        </div>

        <Grid container spacing={1.2} className='mt-2'>
          {[50, 100, 150, 200, 250, 300].map((value, index) => {
            const isSelected = value === amount;
            return (
              <Grid key={index} item xs={4}>
                <Button
                  fullWidth
                  size='medium'
                  variant={isSelected ? 'contained' : 'outlined'}
                  color={isSelected ? 'primary' : 'info'}
                  className='h-[40px] rounded-[8px] text-[20px]'
                  onClick={() => setValue('amount', value)}
                >
                  {value}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions className='justify-center'>
        <LoadingButton variant='contained' className='w-[80%]' loading={isLoading} onClick={handleClickSubmit}>
          Xác nhận
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupGameBet;
