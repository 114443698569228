import { ArrowBack, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AppBar, Container, FormControl, IconButton, Paper, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { InputNumber } from 'components/common';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { authService, gamesService, queryClient } from 'services';
import { InternetBanking } from 'views/Account';

const ExchangePoint = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { data: profile } = useQuery(['authService.getProfile'], () => authService.getProfile());
  const linked = !!profile?.bankAccountNumber;

  const { mutate: createWithdraw, isLoading } = useMutation(gamesService.createWithdraw, {
    onSuccess: () => {
      queryClient.invalidateQueries(['authService.getProfile']);
      enqueueSnackbar('Đã gửi yêu cầu');
      navigate(-1);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      createWithdraw({
        ...(values as WithdrawCreateType),
      });
    })();
  };

  return (
    <div className='pt-20'>
      <AppBar
        component={Container}
        elevation={1}
        className='shadow-md inset-0 bottom-[unset] h-[54px] flex flex-row items-center gap-1 px-2'
      >
        <Link to={privateRoute.account.path}>
          <IconButton color='inherit'>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className='text-lg font-medium'>Quy đổi điểm thưởng</span>
      </AppBar>

      <div className='space-y-8'>
        <Controller
          control={control}
          name='amount'
          defaultValue=''
          rules={{
            required: 'Số điểm không được để trống',
            min: { value: 50, message: 'Quy đổi tối thiểu 50 điểm để đủ điều kiện' },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Số điểm quy đổi</label>
              <TextField
                {...field}
                InputProps={{
                  inputComponent: InputNumber,
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />

        <div>
          {!linked ? (
            <>
              <div className='text-sm mb-3'>* Chưa liên kết ngân hàng</div>
              <InternetBanking />
            </>
          ) : (
            <Paper>
              <div className='flex justify-between items-center px-4 py-2 border-b border-black/10'>
                <div>Thông tin ngân hàng</div>
                <Link to={privateRoute.banking.path}>
                  <IconButton>
                    <Edit fontSize='small' />
                  </IconButton>
                </Link>
              </div>
              <div className='px-4 py-3 space-y-2'>
                <div>{profile.bankName}</div>
                <div>{profile.bankAccountNumber}</div>
                <div>{profile.bankAccountHolder}</div>
              </div>
            </Paper>
          )}
        </div>

        <div>
          <LoadingButton
            fullWidth
            size='large'
            variant='contained'
            color='primary'
            className='rounded-full'
            disabled={!linked}
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Gửi yêu cầu
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default ExchangePoint;
