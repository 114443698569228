import { Check } from '@mui/icons-material';
import { Avatar } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { formatBalance } from 'utils/common';

const KEY = 'Fake';

const Snackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!process.env.REACT_APP_FAKE_NOTIFY) return;
    const nextAlert = setInterval(() => {
      const id = Math.floor(Math.random() * 50000) + 50000;
      const money = Math.floor(Math.random() * 1000) + 10;
      const second = Math.floor(Math.random() * 30) + 10;
      enqueueSnackbar(
        <div className='flex gap-2' onClick={() => closeSnackbar(KEY)}>
          <Avatar className='bg-transparent border-2 border-success text-success'>
            <Check />
          </Avatar>
          <div className='text-white/90'>
            <div className='text-base'>
              ID {id} vừa rút thành công {formatBalance(money * 1000)}
            </div>
            <div className='text-sm mt-1'>{second} giây trước</div>
          </div>
        </div>,
        {
          key: KEY,
          variant: 'default',
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
        },
      );
    }, 15000);
    return () => {
      clearInterval(nextAlert);
    };
  }, [enqueueSnackbar, closeSnackbar]);

  return <div></div>;
};

export default Snackbar;
