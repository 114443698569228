import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputPassword } from 'components/common';
import { User } from 'components/icons';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signIn } from 'reducers/profileSlice';
import { authRoute } from 'routes';
import { authService } from 'services';

const RegisterScreen = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });
  const { password } = watch();

  const { mutate: register, isLoading } = useMutation(authService.register, {
    onSuccess: (data, vars) => {
      enqueueSnackbar('Đăng ký thành công', { variant: 'success' });

      authService.login(vars).then(({ tokens, player }) => {
        dispatch(
          signIn({
            accessToken: tokens.access.token,
            refreshToken: tokens.refresh.token,
            ...player,
          }),
        );
      });
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      register(values as LoginBody);
    })();
  };

  return (
    <div className='md:pb-[24px] pb-[12px]'>
      <div className='h-[52px] flex items-center'>
        <Link to={authRoute.login.url}>
          <IconButton color='inherit'>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className='font-bold text-lg'>Đăng ký</span>
      </div>
      <div className='h-[180px] flex justify-center items-center'>
        <img src={require('assets/icons/Logo.App.png')} className='w-[320px]' />
      </div>

      <div className='flex flex-col items-stretch gap-[32px] my-[40px] p-4 rounded bg-white relative'>
        <Controller
          name='username'
          defaultValue=''
          control={control}
          rules={{
            required: 'Tài khoản không được để trống',
            minLength: { value: 6, message: 'Tài khoản có ít nhất 6 ký tự' },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              placeholder='Tài khoản'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <User />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiFormHelperText-root.Mui-error': {
                  color: 'var(--color-error)',
                },
                '& .MuiInputBase-root': {
                  // color: 'var(--color-text-white)',
                  // background: 'linear-gradient(90deg, var(--color-primary-dark) 0%, var(--color-primary-light) 100%)',
                },
                '& .MuiSvgIcon-root > path': {
                  // fill: 'var(--color-text-white)',
                },
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='password'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không được để trống',
            minLength: { value: 6, message: 'Mật khẩu có ít nhất 6 ký tự' },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputPassword
              {...field}
              fullWidth
              placeholder='Mật khẩu'
              sx={{
                '& .MuiFormHelperText-root.Mui-error': {
                  color: 'var(--color-error)',
                },
                '& .MuiInputBase-root': {
                  // color: 'var(--color-text-white)',
                  // background: 'linear-gradient(90deg, var(--color-primary-dark) 0%, var(--color-primary-light) 100%)',
                },
                '& .MuiSvgIcon-root > path': {
                  // fill: 'var(--color-text-white)',
                },
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='passwordConfirm'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không hợp lệ',
            validate: {
              match: (value) => value === password || 'Mật khẩu không khớp',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputPassword
              {...field}
              fullWidth
              placeholder='Xác nhận mật khẩu'
              sx={{
                '& .MuiFormHelperText-root.Mui-error': {
                  color: 'var(--color-error)',
                },
                '& .MuiInputBase-root': {
                  // color: 'var(--color-text-white)',
                  // background: 'linear-gradient(90deg, var(--color-primary-dark) 0%, var(--color-primary-light) 100%)',
                },
                '& .MuiSvgIcon-root > path': {
                  // fill: 'var(--color-text-white)',
                },
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <LoadingButton
          size='large'
          variant='contained'
          color='primary'
          className='w-[240px] mx-auto rounded-full'
          disableElevation={true}
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Đăng ký
        </LoadingButton>

        <div className='flex justify-center gap-2 text-sm z-20'>
          <span>Đã có tài khoản?</span>
          <Link className='font-medium text-secondary-light hover:text-secondary-dark' to={authRoute.login.url}>
            Đăng nhập
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
