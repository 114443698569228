import { Container, Dialog } from '@mui/material';
import { AppFooter, AppSnackbar } from 'containers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { authRoute, privateRoute } from 'routes';
import { socket } from 'utils/socket';
import { PopupAlert } from 'views/Games/components';

const PrivateLayout = () => {
  const navigator = useNavigate();
  const { isReady } = useSelector(systemSelector);
  const { isLoggedIn } = useSelector(profileSelector);

  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!isLoggedIn) {
      socket.disconnect();
    } else {
      socket.connect();
      socket.instance().on('NOTIFICATION', (data: NotificationType) => {
        setOpenAlert(true);
        setMessage(data.content);
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigator(authRoute.login.url, { replace: true });
    }
  }, [isLoggedIn, navigator]);

  return (
    <Container component='main' className='pb-[60px]'>
      {isReady ? (
        <Routes>
          {Object.values(privateRoute).map(({ path, component: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
          <Route path='*' element={<Navigate to={privateRoute.home.path} />} />
        </Routes>
      ) : (
        <></>
      )}
      <AppFooter />
      <AppSnackbar />

      <Dialog open={openAlert} onClose={() => setOpenAlert(false)} fullWidth>
        <PopupAlert onClose={() => setOpenAlert(false)} message={message} />
      </Dialog>
    </Container>
  );
};

export default PrivateLayout;
