import { Account } from 'views/Account';
import { Games } from 'views/Games';
import { Gift } from 'views/Gift';
import { Home } from 'views/Home';
import { ScreenBanking, ScreenExchange, ScreenPassword, ScreenProfile } from 'views/Screens';

const privateRoute = {
  home: {
    path: '/',
    name: 'Home',
    component: Home,
  },
  gift: {
    path: '/gift',
    name: 'Quà Tặng',
    component: Gift,
  },
  games: {
    path: '/games',
    name: 'Invest',
    component: Games,
  },
  account: {
    path: '/account',
    name: 'Tài Khoản',
    component: Account,
  },

  exchange: {
    path: '/exchange-point',
    component: ScreenExchange,
  },
  profile: {
    path: '/profile',
    component: ScreenProfile,
  },
  banking: {
    path: '/banking',
    component: ScreenBanking,
  },
  password: {
    path: '/password',
    component: ScreenPassword,
  },
};

export default privateRoute;
