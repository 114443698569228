import { AccessTimeOutlined } from '@mui/icons-material';
import { default as Countdown } from 'react-countdown';
import { socket } from 'utils/socket';

const NoticeClock = ({ currentSession }: { currentSession: SessionType }) => {
  return (
    <div className='flex justify-between items-center py-3'>
      <div className='text-lg'>
        Tiếp theo - <span className='font-bold'>{currentSession.incId}</span>
      </div>
      <div className='flex items-center space-x-1'>
        <AccessTimeOutlined color='inherit' />
        <Countdown
          overtime
          onComplete={() => {
            console.log('NO SESSION NEW => ON COMPLETE');
            setTimeout(() => {
              socket.disconnect();
              socket.connect();
            }, 2000);
          }}
          date={currentSession.endTime}
          renderer={({ formatted: { minutes, seconds } }) => (
            <span className='text-lg font-bold'>
              {minutes}:{seconds}
            </span>
          )}
        />
      </div>
    </div>
  );
};

export default NoticeClock;
