import { LoadingButton } from '@mui/lab';
import { InputAdornment, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputPassword } from 'components/common';
import { User } from 'components/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { signIn } from 'reducers/profileSlice';
import { authRoute } from 'routes';
import { authService } from 'services';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: ({ player, tokens }) => {
      dispatch(signIn({ accessToken: tokens.access.token, ...player }));
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      login(values as LoginBody);
    })();
  };

  return (
    <div className='md:py-[24px] py-[12px]'>
      <div className='h-[236px] flex justify-center items-center'>
        <img src={require('assets/icons/Logo.App.png')} className='w-[320px]' />
      </div>

      <div className='flex flex-col items-stretch gap-[32px] my-[40px] p-4 rounded bg-white relative'>
        <Controller
          name='username'
          defaultValue={state?.username ?? ''}
          control={control}
          rules={{
            required: 'Tài khoản không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              placeholder='Tài khoản'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <User />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiFormHelperText-root.Mui-error': {
                  color: 'var(--color-error)',
                },
                '& .MuiInputBase-root': {
                  // color: 'var(--color-text-white)',
                  // background: 'linear-gradient(90deg, var(--color-primary-dark) 0%, var(--color-primary-light) 100%)',
                },
                '& .MuiSvgIcon-root > path': {
                  // fill: 'var(--color-text-white)',
                },
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name='password'
          defaultValue={state?.password ?? ''}
          control={control}
          rules={{
            required: 'Mật khẩu không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <InputPassword
              {...field}
              fullWidth
              placeholder='Mật khẩu'
              onKeyDown={handleKeyDown}
              sx={{
                '& .MuiFormHelperText-root.Mui-error': {
                  color: 'var(--color-error)',
                },
                '& .MuiInputBase-root': {
                  // color: 'var(--color-text-white)',
                  // background: 'linear-gradient(90deg, var(--color-primary-dark) 0%, var(--color-primary-light) 100%)',
                },
                '& .MuiSvgIcon-root > path': {
                  // fill: 'var(--color-text-white)',
                },
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <div className='flex justify-end my-[-10px]'>
          <Link
            className='font-medium text-secondary-light hover:text-secondary-dark'
            to={authRoute.forgotPassword.url}
          >
            Quên mật khẩu?
          </Link>
        </div>

        <LoadingButton
          size='large'
          variant='contained'
          color='primary'
          className='w-[240px] mx-auto rounded-full'
          disableElevation={true}
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Đăng nhập
        </LoadingButton>

        <div className='flex justify-center gap-2 text-sm z-20'>
          <span>Bạn chưa có tài khoản?</span>
          <Link className='font-medium text-secondary-light hover:text-secondary-dark' to={authRoute.register.url}>
            Đăng ký ngay
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
