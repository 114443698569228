import { DialogContent, DialogTitle } from '@mui/material';
import { CloseButton } from 'components/common';

type PopupProps = PopupController & {};

const PopupPasswordHelp = ({ onClose }: PopupProps) => {
  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Đổi mật khẩu</DialogTitle>
      <DialogContent>
        <div className='text-center my-10'>
          <div>
            Liên hệ Hotline CSKH để tiến hành
            <br />
            cài đặt lại mật khẩu
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export default PopupPasswordHelp;
