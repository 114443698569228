import { useQuery } from '@tanstack/react-query';
import { InfiniteSlider } from 'components';
import { gamesService } from 'services';

const SliceBanner = () => {
  const { data } = useQuery(['gamesService.fetchBanners'], () => gamesService.fetchBanners());
  const { results } = data ?? {};

  return (
    <InfiniteSlider {...{ autoplaySpeed: 10000, autoplay: true, dots: true, arrows: false }}>
      {results?.map((item, index) => (
        <div key={index}>
          <div
            className='sm:h-[180px] h-[140px]'
            style={{ background: `url(${item.image}) no-repeat top center / cover` }}
          ></div>
        </div>
      ))}
    </InfiniteSlider>
  );
};

export default SliceBanner;
