import { client } from './axios';

const login = (body: LoginBody): Promise<LoginResponse> => client.post(`/v1/auth/login`, body);
const register = (body: RegisterBody): Promise<RegisterResponse> => client.post(`/v1/auth/register`, body);
const getProfile = (): Promise<UserType> => client.get(`/v1/auth/profile`);

const updatePassword = (body: UpdatePasswordBody): Promise<UserType> => client.post(`/v1/auth/change-password`, body);
const updateProfile = ({ id, ...body }: UpdateProfileBody): Promise<UserType> =>
  client.patch(`/v1/players/${id}`, body);

const authService = {
  login,
  register,
  getProfile,

  updatePassword,
  updateProfile,
};
export default authService;
