import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d='M11.9899 15.7961V11.3771'
      stroke='#1E2843'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9899 8.20432H11.9999'
      stroke='#1E2843'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.3346 2.75021H7.66561C4.64461 2.75021 2.75061 4.88921 2.75061 7.91621V16.0842C2.75061 19.1112 4.63561 21.2502 7.66561 21.2502H16.3336C19.3646 21.2502 21.2506 19.1112 21.2506 16.0842V7.91621C21.2506 4.88921 19.3646 2.75021 16.3346 2.75021Z'
      stroke='#1E2843'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
);

export default Icon;
