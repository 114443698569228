import { LiveChatWidget } from '@livechat/widget-react';
import { AutoGraphRounded } from '@mui/icons-material';
import { ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import { Chat, Gift, Home, UserGroup } from 'components/icons';
import { LIVE_CHAT_LICENSE } from 'env';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { privateRoute } from 'routes';

const StyledListItem = styled(ListItemButton)({
  height: 60,
  fontSize: 12,
  fontWeight: 500,
  padding: '10px 0px',
  flexDirection: 'column',
  color: '#888',
  '&:hover, &.Mui-selected': {
    color: 'var(--color-primary-dark)',

    '& svg.text-transparent > path': {
      stroke: 'var(--color-primary-dark)',
    },
    '& svg:not(.text-transparent) > path': {
      fill: 'var(--color-primary-dark)',
    },
  },
});

const NavItem = ({ path, name, icon }: { path: string; name: string; icon?: any }) => {
  const { pathname } = useLocation();
  const isHome = path === '/';
  return (
    <Link to={path} className='flex-1'>
      <StyledListItem selected={isHome ? pathname === path : pathname.startsWith(path)}>
        {icon}
        <span className='whitespace-nowrap'>{name}</span>
      </StyledListItem>
    </Link>
  );
};

const Menu = () => {
  const { home, gift, games, account } = privateRoute;
  const { incId } = useSelector(profileSelector);

  const [visible, setVisible] = useState<'maximized' | 'minimized' | 'hidden'>('hidden');

  return (
    <div className='flex'>
      <NavItem {...gift} icon={<Gift />} />
      <NavItem {...home} icon={<Home className='text-transparent' />} />
      <NavItem {...games} icon={<AutoGraphRounded />} />
      <NavItem {...account} icon={<UserGroup className='text-transparent' />} />

      <StyledListItem className='flex-1' onClick={() => setVisible('maximized')}>
        <Chat className='text-transparent' />
        <span className='whitespace-nowrap'>CSKH</span>
      </StyledListItem>
      <LiveChatWidget
        license={LIVE_CHAT_LICENSE}
        visibility={visible}
        customerName={'' + incId}
        onVisibilityChanged={({ visibility }) => setVisible(visibility)}
      />
    </div>
  );
};

export default Menu;
