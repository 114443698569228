import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { authRoute } from 'routes';

const ForgotPassword = () => {
  return (
    <div className='md:pb-[24px] pb-[12px]'>
      <div className='h-[52px] flex items-center'>
        <Link to={authRoute.login.url}>
          <IconButton color='inherit'>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className='font-bold text-lg'>Quên mật khẩu</span>
      </div>
      <div className='h-[180px] flex justify-center items-center'>
        <img src={require('assets/icons/Logo.App.png')} className='w-[320px]' />
      </div>

      <div className='text-center my-[40px] bg-primary-fill p-6 rounded'>
        <div>
          Liên hệ Hotline CSKH để tiến hành
          <br />
          lấy lại mật khẩu
        </div>

        <div className='mt-6'>
          <Link className='font-medium text-secondary-light hover:text-secondary-dark' to={authRoute.login.url}>
            Quay lại đăng nhập
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
