import { styled, Tooltip, TooltipProps } from '@mui/material';

const OpenedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow open placement='top' classes={{ popper: className }} />
))(({ theme }) => ({
  [`&.MuiTooltip-popperInteractive`]: {
    zIndex: 10,
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#465A7A',
  },
  [`& .MuiTooltip-tooltip`]: {
    color: '#E1E1E1',
    background: 'transparent',
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 0,
  },
}));

export default OpenedTooltip;
