import { Container, Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { authService } from 'services';
import { formatBalance } from 'utils/common';

const StickyBalance = () => {
  const { ...profile } = useSelector(profileSelector);

  const { data: balance } = useQuery(['balance'], () => authService.getProfile().then((profile) => profile.balance), {
    initialData: profile.balance,
    refetchInterval: 10000,
  });

  return (
    <Container className='fixed inset-0 top-[unset] bottom-[68px] px-3 z-20'>
      <Paper className='flex items-center justify-between bg-primary-fill border border-black/10 p-2'>
        <span>
          ID: <span className='text-lg'>{profile.incId}</span>
        </span>

        <span className='text-primary-dark text-lg'>{formatBalance(balance)}</span>
      </Paper>
    </Container>
  );
};

export default StickyBalance;
