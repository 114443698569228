import { AppBar, Container } from '@mui/material';
import { AppMenu } from 'containers';

const Footer = () => {
  return (
    <AppBar component={Container} color='transparent' className='fixed inset-0 top-[unset] bg-white px-0'>
      <AppMenu />
    </AppBar>
  );
};

export default Footer;
