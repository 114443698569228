import { Dialog, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { NoticeRunner } from 'components';
import { Gift as GiftIcon, InfoSquare } from 'components/icons';
import { useState } from 'react';
import { authService } from 'services';
import { PopupGiftInfo, PopupGiftOpen } from './components';

const Gift = () => {
  const [openInfo, setOpenInfo] = useState(false);
  const [openIndex, setOpenIndex] = useState(0);

  const { data: openedIndex = [], refetch } = useQuery(['boxPosision'], () =>
    authService.getProfile().then((profile) => profile.boxPosision?.split(' ').map((position) => +position - 1)),
  );

  return (
    <div>
      <div
        style={{ background: `url(${require('assets/images/Cover.Win.png')}) no-repeat bottom / cover` }}
        className='-container sm:h-[180px] h-[140px]'
      ></div>
      <div className='-container'>
        <NoticeRunner />
      </div>

      <div className='my-6'>
        <Paper className='h-[40px] flex items-center justify-between px-3 border-l-2 border-primary-dark'>
          <div>Giới thiệu</div>
          <GiftIcon sx={{ '& path': { fill: 'var(--color-text-main)' } }} />
        </Paper>
        <Paper className='mt-2 px-3 py-2'>
          Mở Lucky Box, nhận hàng ngàn ưu đãi giảm giá, sản phẩm, tích điểm do nhãn hàng đồng tài trợ.
        </Paper>
      </div>

      <div className='my-6'>
        <Paper className='h-[40px] flex items-center justify-between px-3 border-l-2 border-primary-dark'>
          <div className='flex-1 overflow-hidden relative'>
            <div className='opacity-0'>.</div>
            <div className='absolute inset-0 right-[unset] whitespace-nowrap running'>
              <span className='mr-10'>Hệ thống Hanwha Connect đang tìm kiếm thành viên may mắn ngẫu nhiên</span>
              <span className='mr-10'>Hệ thống Hanwha Connect đang tìm kiếm thành viên may mắn ngẫu nhiên</span>
            </div>
          </div>
          <Tooltip title='Xem chi tiết'>
            <IconButton size='small' className='mr-[-5px]' onClick={() => setOpenInfo(true)}>
              <InfoSquare className='text-transparent' />
            </IconButton>
          </Tooltip>

          <Dialog open={openInfo} onClose={() => setOpenInfo(false)}>
            <PopupGiftInfo onClose={() => setOpenInfo(false)} />
          </Dialog>
        </Paper>
        <Paper className='mt-2 px-3 py-3'>
          <Grid container spacing={2}>
            {Array.from({ length: 9 }).map((_, index) => {
              const disabled = openedIndex.includes(index);
              return (
                <Grid item key={index} xs={4} className={disabled ? 'opacity-40' : ''}>
                  <img
                    src={require('assets/images/Image.Gift.png')}
                    className={disabled ? '' : 'cursor-pointer hover:scale-105'}
                    onClick={() => {
                      if (disabled) return;
                      setOpenIndex(index + 1);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>

          <Dialog open={openIndex > 0} onClose={() => setOpenIndex(0)} fullWidth>
            <PopupGiftOpen index={openIndex} onClose={() => setOpenIndex(0)} onSuccess={refetch} />
          </Dialog>
        </Paper>
      </div>
    </div>
  );
};

export default Gift;
