import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AppBar, Container, FormControl, IconButton, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { authService, queryClient } from 'services';

const Profile = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { data: profile } = useQuery(['authService.getProfile'], () => authService.getProfile());

  const { mutate: updateProfile, isLoading } = useMutation(authService.updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['authService.getProfile']);
      enqueueSnackbar('Đã cập nhật thông tin');
      navigate(-1);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateProfile({
        ...(values as UpdateProfileBody),
        id: profile?.id!,
      });
    })();
  };

  return (
    <div className='pt-20'>
      <AppBar
        component={Container}
        elevation={1}
        className='shadow-md inset-0 bottom-[unset] h-[54px] flex flex-row items-center gap-1 px-2'
      >
        <Link to={privateRoute.account.path}>
          <IconButton color='inherit'>
            <ArrowBack />
          </IconButton>
        </Link>
        <span className='text-lg font-medium'>Thông tin cá nhân</span>
      </AppBar>

      <div className='space-y-8'>
        <Controller
          control={control}
          name='nickname'
          defaultValue={profile?.nickname ?? ''}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Nickname</label>
              <TextField
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name='phoneNumber'
          defaultValue={profile?.phoneNumber ?? ''}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Số điện thoại</label>
              <TextField
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name='address'
          defaultValue={profile?.address ?? ''}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label>Địa chỉ</label>
              <TextField
                {...field}
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-main)',
                    background: '#FFFC',
                  },
                }}
              />
            </FormControl>
          )}
        />

        <div className='pt-4'>
          <LoadingButton
            fullWidth
            size='large'
            variant='contained'
            color='primary'
            className='rounded-full'
            loading={isLoading}
            onClick={handleClickSubmit}
          >
            Cập nhật thông tin
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default Profile;
