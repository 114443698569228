import {
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CloseButton } from 'components/common';
import { useSearch } from 'hooks';
import { gamesService } from 'services';

type PopupProps = PopupController & {
  zone: string;
};

const PopupListSession = ({ zone, onClose }: PopupProps) => {
  const [dataSearch] = useSearch({ limit: 100 });

  const { data } = useQuery(['gamesService.fetchSessions', dataSearch, zone], () =>
    gamesService.fetchSessions({ ...dataSearch, zone }),
  );
  const { results } = data ?? {};

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Lịch sử Invest</DialogTitle>
      <DialogContent className='p-2'>
        <TableContainer component={Paper} variant='outlined' className='p-0'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kỳ</TableCell>
                <TableCell colSpan={2}>Kết quả</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.incId}</TableCell>
                  <TableCell>{item.result}</TableCell>
                  <TableCell className='w-[80px]'>
                    <div className='flex'>
                      {'56789'.includes(item.result.charAt(0)) && (
                        <span style={{ color: '#E553B1', width: 40 }}>Cao</span>
                      )}
                      {'01234'.includes(item.result.charAt(0)) && (
                        <span style={{ color: '#EC9F25', width: 40 }}>Thấp</span>
                      )}
                      {'13579'.includes(item.result.charAt(2)) && (
                        <span style={{ color: '#0C8ADE', width: 40 }}>Trái</span>
                      )}
                      {'02468'.includes(item.result.charAt(2)) && (
                        <span style={{ color: '#6F57E8', width: 40 }}>Phải</span>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </>
  );
};

export default PopupListSession;
