import { DialogContent, DialogTitle } from '@mui/material';
import { CloseButton } from 'components/common';

type PopupProps = PopupController & {};

const PopupDepositHelp = ({ onClose }: PopupProps) => {
  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Nạp điểm</DialogTitle>
      <DialogContent>
        <div className='text-center my-10'>
          <div>
            Liên hệ Hotline CSKH để tiến hành
            <br />
            nạp điểm vào tài khoản
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export default PopupDepositHelp;
