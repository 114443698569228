import { API_URL } from 'env';
import { saveProfile } from 'reducers/profileSlice';
import { store } from 'reducers/store';
import { NotificationType, SessionNew, SystemState, initSession, newSession } from 'reducers/systemSlice';
import { io } from 'socket.io-client';

let instance = io({ autoConnect: false });

const setupListeners = () => {
  instance.on('STATUS', (data: SystemState) => {
    store.dispatch(initSession(data));
    console.log('SOCKET - INIT STATUS');
  });
  instance.on('NEW_SESSION', (data: SessionNew) => {
    store.dispatch(newSession(data));
    console.log('SOCKET - NEW SESSION');
  });

  instance.on('NEW_BALANCE', (balance: number) => {
    store.dispatch(saveProfile({ balance }));
  });
  instance.on('NOTIFICATION', (data: NotificationType) => {
    const { bet: isSuccess } = data;
    if (isSuccess) {
      const Ding = new Audio(require('assets/sounds/Ding.mp3'));
      Ding.play();
    }
  });
};

export const socket = {
  connect: () => {
    console.log('1. SOCKET CONNECT');
    const { isLoggedIn, accessToken }: ProfileType = store.getState().profile;
    if (isLoggedIn) {
      instance = io(API_URL!, {
        autoConnect: true,
        auth: { token: `Bearer ${accessToken}` },
      });
      setupListeners();
    }
  },
  disconnect: () => {
    try {
      instance.disconnect();
      console.log('2. SOCKET DISCONNECT');
    } catch {}
  },
  instance: () => instance,
};
