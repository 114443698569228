import { ArrowForwardIos } from '@mui/icons-material';
import {
  Dialog,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearch, useTabs } from 'hooks';
import { useState } from 'react';
import { gamesService } from 'services';
import { formatBalance } from 'utils/common';
import { PopupListStats } from 'views/Account/components';
import { PopupListSession } from '.';

const TABS = [
  { code: 'session', label: 'Lịch sử Invest' },
  { code: 'stats', label: 'Lịch sử tham gia' },
];

const StickyListSession = ({ zone }: { zone: string }) => {
  const [activeTab, onTabChange] = useTabs(TABS);
  const [dataSearch] = useSearch({ limit: 6 });

  const [openSession, setOpenSession] = useState(false);
  const [openStats, setOpenStats] = useState(false);

  const { data: session } = useQuery(['gamesService.fetchSessions', dataSearch, zone], () =>
    gamesService.fetchSessions({ ...dataSearch, zone }),
  );
  const { data: stats } = useQuery(['gamesService.fetchStats', dataSearch], () =>
    gamesService.fetchStats({ ...dataSearch }),
  );

  const isSession = activeTab === 'session';

  return (
    <div>
      <TableContainer component={Paper}>
        <Tabs value={activeTab} onChange={onTabChange} textColor='inherit' variant='fullWidth'>
          {TABS.map((tab) => (
            <Tab key={tab.code} label={tab.label} value={tab.code} className='p-0' />
          ))}
        </Tabs>
        {isSession ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kỳ</TableCell>
                <TableCell colSpan={2}>Kết quả</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {session?.results.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.incId}</TableCell>
                  <TableCell>{item.result}</TableCell>
                  <TableCell className='w-[80px]'>
                    <div className='flex'>
                      {'56789'.includes(item.result.charAt(0)) && (
                        <span style={{ color: '#E553B1', width: 40 }}>Cao</span>
                      )}
                      {'01234'.includes(item.result.charAt(0)) && (
                        <span style={{ color: '#EC9F25', width: 40 }}>Thấp</span>
                      )}
                      {'13579'.includes(item.result.charAt(2)) && (
                        <span style={{ color: '#0C8ADE', width: 40 }}>Trái</span>
                      )}
                      {'02468'.includes(item.result.charAt(2)) && (
                        <span style={{ color: '#6F57E8', width: 40 }}>Phải</span>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3} align='center'>
                  <span
                    className='cursor-pointer text-primary-light hover:text-primary-dark'
                    onClick={() => setOpenSession(true)}
                  >
                    Xem chi tiết <ArrowForwardIos className='text-sm' />
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kỳ</TableCell>
                <TableCell align='right'>Giao dịch</TableCell>
                <TableCell align='right'>Bonus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stats?.results.slice(0, dataSearch.limit).map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.session.incId}</TableCell>
                  <TableCell align='right'>{formatBalance(item.totalBet)}</TableCell>
                  <TableCell align='right'>{formatBalance(item.totalWinnings)}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={4} align='center'>
                  <span
                    className='cursor-pointer text-primary-light hover:text-primary-dark'
                    onClick={() => setOpenStats(true)}
                  >
                    Xem chi tiết <ArrowForwardIos className='text-sm' />
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Dialog open={openSession} onClose={() => setOpenSession(false)} fullScreen>
        <PopupListSession onClose={() => setOpenSession(false)} zone={zone} />
      </Dialog>
      <Dialog open={openStats} onClose={() => setOpenStats(false)} fullScreen>
        <PopupListStats onClose={() => setOpenStats(false)} />
      </Dialog>
    </div>
  );
};

export default StickyListSession;
