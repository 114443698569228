import { DialogContent, DialogTitle } from '@mui/material';
import { CloseButton } from 'components/common';

type PopupProps = PopupController & {};

const PopupGameInfo = ({ onClose }: PopupProps) => {
  return (
    <>
      <div className='fixed top-[30%] left-[50%] translate-x-[-50%] opacity-30 z-10'>
        <img src={require('assets/icons/Icon.Dang.png')} className='rounded-full w-[180px]' />
      </div>
      <CloseButton onClick={onClose} />
      <DialogTitle>Điều khoản và quy định</DialogTitle>
      <DialogContent className='p-2'>
        <p className='mb-3'>
          - Đối với những nhà đầu tư tham gia trúng giải "Nhà đầu tư thông thái" sẽ phải hoàn thành kích hoạt đối chiếu
          hồ sơ nhận thưởng để xác định danh tính chủ sở hữu mang ID với đúng giá trị được nhận bằng hình thức nạp tiền
          kích hoạt. Số tiền nạp kích hoạt trên sẽ được rút kèm cùng toàn bộ số tiền có trong tài khoản sau khi người
          tham gia hoàn thành đối chiếu hồ sơ hợp lệ đúng quy định.
        </p>
        <p className='mb-3'>
          <b>Điều khoản và Quy định:</b> Căn cứ theo xu hướng để tham gia phiên giao dịch.
        </p>
        <p className='text-justify mb-3'>
          <b>Cao - Thấp Xu Hướng:</b> Kết quả ra đơn vị 0-1-2-3-4 là phiên giao dịch kết thúc với xu hướng THẤP. Kết quả
          ra đơn vị 5-6-7-8-9 là phiên giao dịch kết thúc với xu hướng CAO.
        </p>
        <p className='text-justify mb-3'>
          <b>Trái - Phải Xu Hướng:</b> Căn cứ theo đơn đặt vào các vị trí, ví dụ: Ô cuối cùng ra đơn vị 0-2-4-6-8 phiên
          giao dịch kết thúc với xu hướng là TRÁI, Ô cuối cùng ra đơn vị 1-3-5-7-9 phiên giao dịch kết thúc với xu hướng
          là PHẢI.
        </p>
        <p className='text-justify mb-3'>
          INVESTMENT COMPANY nghiêm cấm các hành vi gian lận có hành vi tham gia giao dịch cùng một lúc vào 2 xu hướng,
          , INVESTMENT COMPANY có quyền thu hồi toàn bộ số điểm và đóng băng ID của Quý khách khi có hành vi gian lận sử
          dụng phần mềm hỗ trợ.
        </p>
        <p className='text-justify mb-3'>
          Nếu Quý khách có bất kì kiến nghị nào vui lòng chọn mục CSKH để được nhân viên hỗ trợ tư vấn và giải đáp.
        </p>

        <p className='font-bold mb-3'>Thuế:</p>
        <p className='text-justify mb-3'>
          Để đảm bảo được INVESTMENT COMPANY hoạt động lâu dài, gắn bó cùng Quý khách hàng cũng như đóng thuế cho Bộ
          Công Thương, đồng hành cùng các đơn vị tài trợ, Trong trường hợp Quý Khách nhận được phần thưởng và rút tiền
          hạn mức cao cụ thể như sau:
        </p>
        <p className='text-justify mb-3'>
          - Đối với Quý khách hàng rút hạn mức từ <span className='text-primary-dark'>150.000 điểm</span> -{' '}
          <span className='text-primary-dark'>250.000 điểm</span> vui lòng đóng mức thuế 15% / tổng số điểm Quý khách
          rút ra.
        </p>
        <p className='text-justify mb-3'>
          - Đối với Quý khách hàng rút hạn mức từ <span className='text-primary-dark'>250.000 điểm</span> -{' '}
          <span className='text-primary-dark'>499.000 điểm</span> vui lòng đóng mức thuế 20% / tổng số điểm Quý khách
          rút ra.
        </p>
        <p className='text-justify mb-3'>
          - Đối với Quý khách hàng rút hạn mức trên <span className='text-primary-dark'>500.000</span> điểm tương ứng{' '}
          <span className='text-primary-dark'>500.000.000 VNĐ</span> vui lòng đóng mức thuế 30% / tổng số điểm Quý khách
          rút ra.
        </p>
        <p className='text-justify mb-3'>
          Ngoài ra nếu Quý khách rút hạn mức từ <span className='text-primary-dark'>1.000.000</span> điểm tương ứng{' '}
          <span className='text-primary-dark'>1.000.000.000 VNĐ</span>, Quý khách bắt buộc phải thực hiện thêm nghĩa vụ
          đóng thuế thu nhập cá nhân (TNCN) 10% / tổng số điểm Quý khách rút ra theo luật Thuế TNCN hiện hành.
        </p>
        <p className='text-justify mb-3'>
          Sau khi thực hiện toàn bộ nghĩa vụ đóng thuế , tài khoản của Quý khách sẽ đủ điều kiện xuất khoản. Hệ thống sẽ
          kê khai và xuất khoản cho Quý khách.
        </p>
      </DialogContent>
    </>
  );
};

export default PopupGameInfo;
