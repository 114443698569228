import { Button, CardMedia, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type PopupProps = PopupController & {
  message: string;
};

const PopupAlert = ({ onClose, message }: PopupProps) => {
  return (
    <>
      <img src={require('assets/images/Cover.Money.png')} className='fixed inset-0 bottom-[unset] mx-auto z-10' />
      <DialogTitle>Thông báo</DialogTitle>
      <DialogContent>
        <CardMedia className='h-[160px] bg-contain mb-6' image={require('assets/images/Image.Money.png')}>
          <div></div>
        </CardMedia>
        <div className='text-center'>{message}</div>
      </DialogContent>

      <DialogActions className='justify-center'>
        <Button variant='contained' className='w-[80%]' onClick={onClose}>
          Đồng ý
        </Button>
      </DialogActions>
    </>
  );
};

export default PopupAlert;
