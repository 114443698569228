import { DialogContent, DialogTitle } from '@mui/material';
import { CloseButton } from 'components/common';

type PopupProps = PopupController & {};

const PopupGiftInfo = ({ onClose }: PopupProps) => {
  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Mở hộp quà</DialogTitle>
      <DialogContent>
        <p className='mb-3'>* Lưu ý mỗi tài khoản được Click mở hộp quà tối đa 3 lần.</p>

        <p className='font-bold mb-3'>Tỉ lệ xác xuất:</p>
        <div>
          <div>
            Điện thoại, máy tính: <span className='text-primary-dark'>1%</span>
          </div>
          <div>
            Điện gia dụng: <span className='text-primary-dark'>3%</span>
          </div>
          <div>
            Thời trang: <span className='text-primary-dark'>5%</span>
          </div>
          <div>
            Hàng tiêu dùng: <span className='text-primary-dark'>40%</span>
          </div>
          <div>
            Mini Game: <span className='text-primary-dark'>51.99%</span>
          </div>
          <div>
            Voucher giảm giá: <span className='text-primary-dark'>100%</span>
          </div>
          <div>
            Tích điểm khuyến mại:
            <br />
            <span className='text-primary-dark'>10 - 10.000 điểm thưởng</span>
          </div>
        </div>
      </DialogContent>
    </>
  );
};

export default PopupGiftInfo;
