import {
  Avatar,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CloseButton } from 'components/common';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { systemSelector } from 'reducers/systemSlice';
import { gamesService } from 'services';
import { formatBalance, formatZone } from 'utils/common';
import { BET_GAMES } from 'views/Games';

type PopupProps = PopupController & {
  id: string;
};

const PopupInfoStats = ({ onClose, id }: PopupProps) => {
  const { currentSessions } = useSelector(systemSelector);
  const { ...profile } = useSelector(profileSelector);
  const { data: stat, isSuccess } = useQuery(['gamesService.getStatInfo', id], () => gamesService.getStatInfo({ id }));

  const isCurrent = currentSessions[stat?.zone!]?.incId === stat?.incId;

  if (!isSuccess) return <></>;
  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Chi tiết kỳ</DialogTitle>
      <DialogContent className='flex flex-col items-stretch space-y-4'>
        <div className='flex justify-center'>
          <div className='text-[24px] font-bold bg-gradient-to-b from-[#FFF] to-[#D6D6D6] text-secondary-light rounded-[12px] px-4 py-1'>
            <span>{stat.incId}</span>
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Thời gian</div>
          <div>{DateTime.fromISO(stat.endTime).toFormat('dd/MM/yyyy hh:mm:ss')}</div>
        </div>
        <div className='flex justify-between'>
          <div>ID người chơi</div>
          <div>{profile.incId}</div>
        </div>
        <div className='flex justify-between'>
          <div>Cấp</div>
          <div>{formatZone(stat.zone)}</div>
        </div>
        <div className='flex justify-between'>
          <div>Kết quả</div>
          <div className='inline-flex space-x-1'>
            {isCurrent ||
              stat.result.split('').map((number, index) => (
                <Avatar
                  key={index}
                  className='w-[24px] h-[24px] text-sm font-medium bg-gradient-to-b from-[#FFF] to-[#D6D6D6] text-secondary-light'
                >
                  {number}
                </Avatar>
              ))}
          </div>
        </div>
        <div className='flex justify-center'>
          <div className='bg-secondary-main text-white rounded-[12px] flex items-center px-4 pt-2 pb-3 my-4'>
            <div className='text-center px-4'>
              <div className='text-[24px] font-bold'>{stat.totalBet}</div>
              <div className='text-sm'>Giao dịch</div>
            </div>
            <Divider orientation='vertical' className='border-white/40 border-r-2 h-[80%]' />
            <div className='text-center px-4'>
              <div className='text-[24px] font-bold'>{stat.totalWinnings}</div>
              <div className='text-sm'>Phần thưởng</div>
            </div>
          </div>
        </div>

        <TableContainer component={Paper} variant='outlined' className='p-0'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Loại</TableCell>
                <TableCell align='right'>Cược</TableCell>
                <TableCell align='right'>Bonus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stat.bets.map((item) => {
                const game = BET_GAMES.find((game) => game.code === item.gate);
                return (
                  <TableRow key={item.id}>
                    <TableCell style={{ color: game?.color }}>{game?.label}</TableCell>
                    <TableCell align='right'>{formatBalance(item.amount)}</TableCell>
                    <TableCell align='right'>{formatBalance(item.winnings)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </>
  );
};

export default PopupInfoStats;
