import { Avatar, Button, Dialog, Grid, IconButton, Paper, Tooltip } from '@mui/material';
import { NoticeClock, NoticeRunner, Partnership } from 'components';
import { OpenedTooltip } from 'components/common';
import { InfoSquare } from 'components/icons';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { systemSelector } from 'reducers/systemSlice';
import { PopupGameBet, PopupGameInfo, StickyBalance, StickyListSession } from './components';

const Games = () => {
  const { currentSessions, lastSessions } = useSelector(systemSelector);

  const [openInfo, setOpenInfo] = useState(false);
  const [openZone, setOpenZone] = useState('LEVEL_3');
  const [openGate, setOpenGate] = useState('');

  const [currentSession, lastSession] = useMemo(
    () => [currentSessions[openZone], lastSessions[openZone]],
    [currentSessions, lastSessions, openZone],
  );

  return (
    <div className='relative pb-[64px]'>
      <div
        style={{ background: `url(${require('assets/images/Cover.Games.png')}) no-repeat center top / cover` }}
        className='-container sm:h-[180px] h-[140px]'
      ></div>
      <div className='-container'>
        <NoticeRunner />
      </div>

      <div className='absolute right-0 top-2'>
        <Tooltip title='Xem chi tiết'>
          <IconButton
            size='small'
            className='mr-[-5px] bg-white/40 hover:bg-white/60'
            onClick={() => setOpenInfo(true)}
          >
            <InfoSquare className='text-transparent' />
          </IconButton>
        </Tooltip>

        <Dialog open={openInfo} onClose={() => setOpenInfo(false)} fullScreen>
          <PopupGameInfo onClose={() => setOpenInfo(false)} />
        </Dialog>
        <Dialog open={openGate !== ''}>
          <PopupGameBet onClose={() => setOpenGate('')} item={{ zone: openZone, gate: openGate }} />
        </Dialog>
      </div>

      <div className='-container flex flex-col items-center p-3'>
        <div className='bg-secondary-dark text-white text-2xl font-bold px-6 py-2 rounded-[8px] tracking-widest z-10'>
          INVEST NUMBER
        </div>
        <div className='flex justify-center -m-3 mt-6'>
          <div className='relative w-[100px] h-[100px] flex justify-center items-center'>
            <OpenedTooltip title='H'>
              <Avatar className='w-[60px] h-[60px] text-[42px] font-bold bg-gradient-to-b from-[#FFF] to-[#D6D6D6] text-primary-main'>
                {lastSession.result.charAt(0)}
              </Avatar>
            </OpenedTooltip>
          </div>
          <div className='relative w-[100px] h-[100px] flex justify-center items-center'>
            <Avatar className='w-[60px] h-[60px] text-[42px] font-bold bg-gradient-to-b from-[#FFF] to-[#D6D6D6] text-primary-main'>
              {lastSession.result.charAt(1)}
            </Avatar>
          </div>
          <div className='relative w-[100px] h-[100px] flex justify-center items-center'>
            <OpenedTooltip title='L'>
              <Avatar className='w-[60px] h-[60px] text-[42px] font-bold bg-gradient-to-b from-[#FFF] to-[#D6D6D6] text-primary-main'>
                {lastSession.result.charAt(2)}
              </Avatar>
            </OpenedTooltip>
          </div>
        </div>
      </div>

      <Paper className='hidden justify-around rounded-[8px] p-3 z-20'>
        {[
          { label: '1 phút', code: 'LEVEL_1' },
          { label: '3 phút', code: 'LEVEL_3' },
          { label: '5 phút', code: 'LEVEL_5' },
          { label: '10 phút', code: 'LEVEL_10' },
        ].map((item, index) => {
          const isActive = item.code === openZone;
          return (
            <div key={index}>
              <Avatar
                className={
                  'w-[60px] h-[60px] border-[4px] cursor-pointer ' +
                  (isActive ? 'border-primary-dark' : 'hover:border-primary-dark')
                }
                src={require('assets/icons/Icon.Clock.png')}
                onClick={() => setOpenZone(item.code)}
              />
              <div className='text-center'>{item.label}</div>
            </div>
          );
        })}
      </Paper>

      <div>
        <NoticeClock currentSession={currentSession} />
      </div>

      <Paper className='-container py-10 rounded-none relative overflow-hidden'>
        <Grid container>
          <Grid item xs={6} className='relative flex justify-end p-[2px]'>
            <Button
              color='inherit'
              className='right-[2px] bottom-[2px] p-0 z-10 cursor-pointer hover:brightness-110'
              style={{ clipPath: 'polygon(97% 0, 100% 0, 100% 100%, 0 100%, 0 97%)' }}
              onClick={() => setOpenGate('CAO')}
            >
              <img src={require('assets/images/Polygon-1.svg').default} className='w-[160px] h-[160px]' />
              <div className='absolute right-[16px] bottom-[16px] text-3xl font-bold text-white'>
                <div className='whitespace-pre-line text-right'>{`CAO`}</div>
              </div>
            </Button>
            <div className='absolute right-0 bottom-0 w-[180px] h-[180px] -rotate-45 flex'>
              <div className='flex-1 flex items-center justify-between text-3xl font-bold text-[#E93672] pb-8'>
                {[5, 6, 7, 8, 9].map((key) => (
                  <span key={key}>{key}</span>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} className='relative flex justify-start p-[2px]'>
            <Button
              color='inherit'
              className='left-[2px] bottom-[2px] p-0 z-10 cursor-pointer hover:brightness-110'
              style={{ clipPath: 'polygon(3% 0, 100% 97%, 100% 100%, 0 100%, 0 0)' }}
              onClick={() => setOpenGate('THAP')}
            >
              <img src={require('assets/images/Polygon-2.svg').default} className='w-[160px] h-[160px]' />
              <div className='absolute left-[16px] bottom-[16px] text-3xl font-bold text-white'>
                <div className='whitespace-pre-line text-left'>{`THẤP`}</div>
              </div>
            </Button>
            <div className='absolute left-0 bottom-0 w-[180px] h-[180px] rotate-45 flex'>
              <div className='flex-1 flex items-center justify-between text-3xl font-bold text-[#D39D22] pb-8'>
                {[0, 1, 2, 3, 4].map((key) => (
                  <span key={key}>{key}</span>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} className='relative flex justify-end p-[2px]'>
            <Button
              color='inherit'
              className='right-[2px] top-[2px] p-0 z-10 cursor-pointer hover:brightness-110'
              style={{ clipPath: 'polygon(100% 0, 100% 100%, 97% 100%, 0 3%, 0 0)' }}
              onClick={() => setOpenGate('TRAI')}
            >
              <img src={require('assets/images/Polygon-3.svg').default} className='w-[160px] h-[160px]' />
              <div className='absolute right-[16px] top-[16px] text-3xl font-bold text-white'>
                <div className='whitespace-pre-line text-right'>{`TRÁI`}</div>
              </div>
            </Button>
            <div className='absolute right-0 top-0 w-[180px] h-[180px] rotate-45 flex'>
              <div className='flex-1 flex items-center justify-between text-3xl font-bold text-[#108B92] pt-8'>
                {[0, 2, 4, 6, 8].map((key) => (
                  <span key={key}>{key}</span>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} className='relative flex justify-start p-[2px]'>
            <Button
              color='inherit'
              className='left-[2px] top-[2px] p-0 z-10 cursor-pointer hover:brightness-110'
              style={{ clipPath: 'polygon(100% 0, 100% 3%, 3% 100%, 0 100%, 0 0)' }}
              onClick={() => setOpenGate('PHAI')}
            >
              <img src={require('assets/images/Polygon-4.svg').default} className='w-[160px] h-[160px]' />
              <div className='absolute left-[16px] top-[16px] text-3xl font-bold text-white'>
                <div className='whitespace-pre-line text-left'>{`PHẢI`}</div>
              </div>
            </Button>
            <div className='absolute left-0 top-0 w-[180px] h-[180px] -rotate-45 flex'>
              <div className='flex-1 flex items-center justify-between text-3xl font-bold text-[#7962F1] pt-8'>
                {[1, 3, 5, 7, 9].map((key) => (
                  <span key={key}>{key}</span>
                ))}
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <div className='my-6'>
        <StickyListSession zone={openZone} />
      </div>

      <Partnership />
      <StickyBalance />
    </div>
  );
};

export default Games;
