export { default as Games } from './Games';

export const BET_TABS = [
  { code: '-createdAt', label: 'Đặt mới' },
  { code: '-amount', label: 'Top đặt cao' },
  { code: '-winnings', label: 'Top thưởng cao' },
];

export const BET_GAMES = [
  { code: 'CAO', color: '#E553B1', label: 'CAO', values: '56789' },
  { code: 'THAP', color: '#EC9F25', label: 'THẤP', values: '01234' },
  { code: 'TRAI', color: '#0C8ADE', label: 'TRÁI', values: '13579' },
  { code: 'PHAI', color: '#6F57E8', label: 'PHẢI', values: '02468' },
];
