import { Groups } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { gamesService } from 'services';

const NoticeRunner = () => {
  const { data: home } = useQuery(['gamesService.openBanner'], () => gamesService.openBanner());
  const size = (home?.notification.length ?? 0) / 7;

  return (
    <Paper className='flex items-center gap-2 px-3 py-1 rounded-none'>
      <img src={require('assets/icons/Icon.Loa.svg').default} />
      <div className='flex-1 text-sm overflow-hidden relative'>
        <div className='opacity-0'>.</div>
        <div
          className='absolute inset-0 right-[unset] whitespace-nowrap running'
          style={{ animationDuration: `${size}s` }}
        >
          <span className='mr-10'>{home?.notification}</span>
          <span className='mr-10'>{home?.notification}</span>
        </div>
      </div>

      <div className='flex items-center gap-1 px-2 py-0.5 bg-primary-main text-white/90 rounded-full'>
        <Groups />
        <span className='text-sm font-bold'>{home?.totalUser}</span>
      </div>
    </Paper>
  );
};

export default NoticeRunner;
