import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d='M21.8 19V12.267C21.8 11.7245 21.6897 11.1876 21.4756 10.689C21.2616 10.1905 20.9484 9.74069 20.555 9.36701L14.178 3.31001C13.8063 2.9569 13.3132 2.76001 12.8005 2.76001C12.2879 2.76001 11.7947 2.9569 11.423 3.31001L5.04505 9.36701C4.6517 9.74069 4.33848 10.1905 4.12445 10.689C3.91042 11.1876 3.80005 11.7245 3.80005 12.267V19C3.80005 19.5304 4.01076 20.0392 4.38584 20.4142C4.76091 20.7893 5.26962 21 5.80005 21H19.8C20.3305 21 20.8392 20.7893 21.2143 20.4142C21.5893 20.0392 21.8 19.5304 21.8 19Z'
      stroke='#9E9E9E'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SvgIcon>
);

export default Icon;
