import {
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CloseButton } from 'components/common';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { gamesService } from 'services';
import { formatBalance } from 'utils/common';

type PopupProps = PopupController & {};

const PopupTopWithdraw = ({ onClose }: PopupProps) => {
  const [dataSearch] = useSearch({ limit: 20 });

  const { data } = useQuery(['gamesService.fetchTops', dataSearch], () => gamesService.fetchTops(dataSearch));
  const { results } = data ?? {};

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>BXH rút tiền</DialogTitle>
      <DialogContent className='p-2'>
        <TableContainer component={Paper} variant='outlined' className='p-0'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align='right'>Số tiền</TableCell>
                <TableCell align='right'>Thời gian</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.idTop}</TableCell>
                  <TableCell align='right'>
                    <div className='relative'>
                      {formatBalance(item.price)}
                      <img
                        src={require('assets/icons/Icon.Coin.svg').default}
                        className='absolute bottom-0 -right-6 w-5'
                      />
                    </div>
                  </TableCell>
                  <TableCell align='right'>{DateTime.fromISO(item.updatedAt).toFormat('hh:mm')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </>
  );
};

export default PopupTopWithdraw;
