import { LoadingButton } from '@mui/lab';
import { CardMedia, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { CloseButton } from 'components/common';
import { gamesService } from 'services';

type PopupProps = PopupController & {
  index: number;
};

const PopupGiftOpen = ({ index, onClose, onSuccess }: PopupProps) => {
  const {
    data: { results: data } = {},
    mutate: openGift,
    isLoading,
    isSuccess,
  } = useMutation(gamesService.openGift, {
    onSuccess: () => {
      onSuccess?.();
    },
  });

  const handleClickSubmit = () => {
    openGift({ position: '' + index });
  };

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Mở hộp quà</DialogTitle>
      <DialogContent>
        <CardMedia
          className='h-[160px] bg-contain my-6'
          image={data?.reward?.image ?? require('assets/images/Image.Gift.png')}
        >
          <div></div>
        </CardMedia>
        <div className='text-center text-white/90'>{data?.reward?.description ?? data?.message}</div>
      </DialogContent>
      <DialogActions className='flex-col justify-center'>
        {isSuccess ? (
          <LoadingButton variant='contained' color='secondary' className='w-[80%]' onClick={onClose}>
            Xác nhận
          </LoadingButton>
        ) : (
          <LoadingButton variant='contained' className='w-[80%]' loading={isLoading} onClick={handleClickSubmit}>
            Mở quà
          </LoadingButton>
        )}
        {data?.reward?.isPlus === false && ( // Should equal false
          <div className='text-xs italic text-secondary-light whitespace-nowrap mt-3'>
            Vui lòng liên hệ CSKH để mở khóa phần quà.
          </div>
        )}
      </DialogActions>
    </>
  );
};

export default PopupGiftOpen;
