import { ArrowForwardIos } from '@mui/icons-material';
import { Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { gamesService } from 'services';
import { formatBalance } from 'utils/common';
import { PopupTopWithdraw } from '.';

const ListTopWithdraw = () => {
  const [dataSearch] = useSearch({ limit: 6 });
  const [openInfo, setOpenInfo] = useState(false);

  const { data } = useQuery(['gamesService.fetchTops', dataSearch], () => gamesService.fetchTops(dataSearch));
  const { results } = data ?? {};

  return (
    <>
      <Paper className='h-[40px] flex justify-between items-center px-3 border-l-2 border-primary-dark'>
        <div>BXH rút tiền</div>
        <div>Tháng {DateTime.now().month}</div>
      </Paper>
      <TableContainer component={Paper} variant='outlined' className='my-2'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align='right'>Số tiền</TableCell>
              <TableCell align='right'>Thời gian</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.idTop}</TableCell>
                <TableCell align='right'>
                  <div className='relative'>
                    {formatBalance(item.price)}
                    <img
                      src={require('assets/icons/Icon.Coin.svg').default}
                      className='absolute bottom-0 -right-6 w-5'
                    />
                  </div>
                </TableCell>
                <TableCell align='right'>{DateTime.fromISO(item.updatedAt).toFormat('hh:mm')}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} align='center'>
                <span
                  className='cursor-pointer text-primary-light hover:text-primary-dark'
                  onClick={() => setOpenInfo(true)}
                >
                  Xem chi tiết <ArrowForwardIos className='text-sm' />
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openInfo} onClose={() => setOpenInfo(false)} fullScreen>
        <PopupTopWithdraw onClose={() => setOpenInfo(false)} />
      </Dialog>
    </>
  );
};

export default ListTopWithdraw;
