import { client } from './axios';

const fetchBanners = (params?: PaginateParams): Promise<BannerPaginateType> => client.get(`/v1/banner`, { params });
const fetchPartners = (params?: PaginateParams): Promise<PartnerPaginateType> => client.get(`/v1/partner`, { params });
const fetchTops = (params?: PaginateParams): Promise<TopPaginateType> => client.get(`/v1/top`, { params });
const fetchBets = (params?: PaginateParams): Promise<BetPaginateType> => client.get(`/v1/bets`, { params });
const fetchSessions = (params?: PaginateParams): Promise<SessionPaginateType> => client.get(`/v1/sessions`, { params });

const fetchDeposits = (): Promise<DepositPaginateType> => client.get(`/v1/deposits`);
const fetchWithdraws = (): Promise<WithdrawPaginateType> => client.get(`/v1/withdrawals`);
const fetchStats = (params?: PaginateParams): Promise<StatsPagniateType> => client.get(`v1/sessions/stats`, { params });
const getStatInfo = ({ id }: { id: string }): Promise<StatInfo> => client.get(`v1/sessions/${id}/stats`);

const openBanner = (): Promise<HomepageType> => client.get(`/v1/system`);
const openGift = (body: OpenGiftBody): Promise<OpenGiftResponse> => client.post(`/v1/reward`, body);
const createBet = (body: BetCreateType): Promise<BetType> => client.post(`/v1/bets`, body);
const createWithdraw = (body: WithdrawCreateType): Promise<TransactionType> => client.post(`/v1/withdrawals`, body);

const gamesService = {
  fetchBanners,
  fetchPartners,
  fetchTops,
  fetchBets,
  fetchSessions,

  fetchDeposits,
  fetchWithdraws,
  fetchStats,
  getStatInfo,

  openBanner,
  openGift,
  createBet,
  createWithdraw,
};
export default gamesService;
