import { DateTime } from 'luxon';

export const formatBalance = (balance?: number) => {
  return (balance ?? 0).toLocaleString();
};

export const formatZone = (zone: string) => zone.replace('_', ' ');

export const formatDate = (time: string) => {
  return DateTime.fromISO(time).toFormat('dd/MM/yyyy');
};
